import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';


import RotatingEye from "./rotating_eye"


// video
import ReactPlayer from 'react-player'
import trailer from '../../images/Trailer.mp4'




class App extends Component {
  constructor() {
    super();
    this.state = { show: false };
  }

  handleClick() { 
    if (this.state.show === false) {
        this.setState({
          show: true
        });
        }
      }
    
      handleClickClose() { 
            this.setState({
              show: false,
              exclusiveShow: "news"
            });
          }
    

  render() {
    return (
      <div id="EyeAsPlayer">


                <button style={{border: "none", boxShadow: "none"}} onClick={ () => this.handleClick() }>      
                  <RotatingEye />
                </button>




        <ToggleDisplay if={this.state.show} tag="section" >

        <button style={{top: "0vw", right: "0", position:"absolute"}} onClick={ () => this.handleClickClose() }>  close video  </button>


        <div style={{top: "10vw", position:"absolute", width:"100%"}}>

        <div style={{clear:"both"}}></div>

        <div className='player-wrapper'  style={{width:"100%", margin:"0", left: "0", top:"0"}}>
            <ReactPlayer url={trailer} playing controls
            width="100%"
            height="100%"
            />
          </div>
  </div>
        </ToggleDisplay>



      </div>
    );
  }
}

export default App;
