import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';




// video
import ReactPlayer from 'react-player'





class VideoButton extends Component {
  constructor() {
    super();
    this.state = { show: false };
  }

  handleClick() { 
    if (this.state.show === false) {
        this.setState({
          show: true
        });
        }
      }
    
    handleClickClose() { 
        this.setState({
            show: false,
            exclusiveShow: "news"
        });
        }


  render() {
    return (
      <>


                <button style={{padding:"1vw"}} onClick={ () => this.handleClick() }>▷ {this.props.label}</button>




        <ToggleDisplay if={this.state.show} tag="section" >



        <div style={{position:"fixed", width:"60%", top: "2vw", left: "20%"}}>
                
                
                <button  onClick={ () => this.handleClickClose() }>  close  </button>


                    <ReactPlayer url={this.props.video} playing controls
                    width="100%"
                    height="100%"
                    />
        </div>
        </ToggleDisplay>



      </>
    );
  }
}

export default VideoButton;
