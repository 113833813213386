



import React from 'react'
import {Wrap, CenterContainer} from "../basics"

import story from '../../images/Story.mp4'

import VideoButton from "../apps/playvideo"


import Gesicht from './gesicht'




// videos



export default () =>
<>

<div style={{paddingTop: "10vw", paddingBottom: "10vw"}}>

  <Wrap>
        <CenterContainer>
        <div style={{marginBottom: "1vw"}}>

                  <Gesicht />
                  </div>

        </CenterContainer>


        <div style={{textAlign:"center"}}>
                  <h2>Geist_AI</h2>
                  <p>In holonoptic™, you will be running a simulation of our Geist_AI™ through which you will control our company and your self.</p>
        </div>







                        <div className="features">


                            <div>

                                 <div className="featureText">  
                                <h4>NONLINEAR STORYTELLING</h4>
                                <p>As you replace our vanished CEO and acquire other companies to expand into the weakened EURO zone, holonoptic™ will highlight the ideological implications of your choices.</p>
                                <VideoButton video={story} label="Training Video" />
                                </div> 



                            </div>
                            <div>

                                <div className="featureText">
                                <h4>CONGLOMERATE CONTROL</h4>
                                <p>Geist_AI™ now supports human resources and office facility management.</p>
                                </div>



                            </div>
                            
                            <div>

                                <div className="featureText">
                                <h4>PSYCHOLOGICAL REALISM</h4>
                                <p>Every action, substance or thing you say impacts your mental state, which the Geist_AI™ constantly observes.</p>
                                <p>Build lasting emotional connections with business partners and transcend into the being you aspire to be.</p>
                                </div>



                            </div>
                                
                        </div>











  </Wrap>



  </div>
</>