import React from 'react'
import { Link } from 'gatsby'




import {Wrap} from "../../components/basics"



import map1 from '../../images/screenshots/map4.jpg'
import self1 from '../../images/screenshots/emotions2.jpg'
import construction1 from '../../images/screenshots/construction.jpg'

function ScreenshotWText(props) {
        return <>
        <Wrap>
        <img src={props.img} alt="" className="screenshotImgDescription" />
        </Wrap>

<div style={{textAlign:"center", paddingBottom:"4vw"}}>
    <p>{props.text}</p>
</div>
</>
      }


export default () =>
<>
 
      <ScreenshotWText img={map1} text="Negotiate your way through mergers or acquire weaker firms by hostile takeover to expand our corprate empire." />
      <ScreenshotWText img={construction1} text="Restructure newly acquired companies, hire & fire employees and construct new facilities." />
      <ScreenshotWText img={self1} text="Use psychoactive substances to control the way you feel and improve your performance." />


      <div style={{textAlign:"center", marginTop: "4vw"}}>
      <Link to="/holonoptic/media">
        SEE ALL SCREENSHOTS
        </Link>
        </div>


 
</>
