import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';
import Link from "gatsby-link"


import RotatingEye from "./rotating_eye"


// apps
import "../apps/gesichtSpace.css"
import NewsGET from "../apps/newsGETall"
import FeaturedNewsGET from "../apps/newsGETfeatured"
import ResultsGET from "../apps/ResultsGET"


import ChatPostFetch from "../apps/chatbot"

// video
import ReactPlayer from 'react-player'
import trailer from '../../images/Trailer.mp4'


class EyeMenu extends Component {
  constructor() {
    super();
    this.state = {  show: false,
                    exclusiveShow: "news",
                    style: {
                      animation: "1.5s ease zoom_animation"
                    },
                    eyeStyle: {
                      opacity: "1.0",
                      transition: "opacity .25s ease-in-out"
                    }
                  };
  }

  handleClick() { 
if (this.state.show === false) {
    this.setState({
      show: true
    });
    }
  }

  handleClickClose() { 
        this.setState({
          show: false,
          exclusiveShow: "news",
          eyeStyle: {
            opacity: "1.0",
            transition: "opacity .25s ease-in-out"
          }
        });
      }

    componentDidMount() {
      }


  handleClickExclusive(string) {
    this.setState({
      exclusiveShow: string
    });

    if (string === "testResults") {
      this.setState({
        eyeStyle: {
          opacity: "0.2",
          transition: "opacity .25s ease-in-out"
        }
      });   
    }

    else {
      this.setState({
        eyeStyle: {
          opacity: "1.0",
          transition: "opacity .25s ease-in-out"

        }
      });        
    }


  }


  render() {
    return (
      <div id="EyeAsMenu" style={this.state.style}>

                <button style={{border: "none", boxShadow: "none"}} onClick={ () => this.handleClick() }>     
                  <div style={this.state.eyeStyle}>
                    <RotatingEye />
                  </div> 
                </button>




  <ToggleDisplay if={this.state.show}  >



                <ToggleDisplay if={this.state.exclusiveShow === "testResults"}  >
                  <div style={{position: "absolute"}}>
                    <ResultsGET />
                  </div>
                </ToggleDisplay>




        <ToggleDisplay if={this.state.exclusiveShow === "news"}  >
      
                <div id="gesicht_space" style={{position: "absolute", right: "0"}}>
                      <FeaturedNewsGET />
                      <hr></hr>
                      <h5 style={{margin:"0"}}>older news</h5>
                      <div className="newsScrollable">
                      <NewsGET />
                      </div>
                </div>


                <div id="gesicht_space" style={{position: "absolute", right: "0", bottom: "0"}}>
                <ChatPostFetch />
                </div>

        </ToggleDisplay>


          <div style={{position: "relative", left: "1vw", width: "99vw"}}>


                <button onClick={ () => this.handleClickClose() }> x close</button>

                <div>
                        <ToggleDisplay if={this.state.exclusiveShow !== "video"}  >
                          <button id="gesicht_button" onClick={ () => this.handleClickExclusive("video") }>▷ Recruiting Video</button>
                        </ToggleDisplay>

                        <ToggleDisplay if={this.state.exclusiveShow === "video"}  >
                              <button className="redButton" onClick={ () => this.handleClickExclusive("news") }>▷ Recruiting Video</button>
                                    <div className='player-wrapper'>
                                              <ReactPlayer url={trailer} playing controls
                                              width="100%"
                                              height="100%"
                                              />
                                    </div>
                        </ToggleDisplay>
                </div>
  
  

                <div>
                      <ToggleDisplay if={this.state.exclusiveShow !== "testResults"}  >
                        <button id="gesicht_button" onClick={ () => this.handleClickExclusive("testResults") }>holonoptic™ results</button>
                      </ToggleDisplay>
        

                      <ToggleDisplay if={this.state.exclusiveShow === "testResults"}  >
                          <button className="redButton" onClick={ () => this.handleClickExclusive("news") }>hide results</button>
                      </ToggleDisplay>
                </div>


                <Link to="/holonoptic/#download"><button className="greenButton">DOWNLOAD THE DEMO</button></Link>
                



            </div>

  </ToggleDisplay>
  </div>
    );
  }
}

export default EyeMenu;



