


import React from 'react'
import {PostWrap} from "../basics"
import Link from "gatsby-link"
import { StaticQuery, graphql } from 'gatsby'

import Img from "gatsby-image"

import './glazial.css'




const GlazialReduced = () => (
<StaticQuery
    query={graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___category, frontmatter___date], order: DESC }) {
        totalCount
        edges {
          node {
            frontmatter {
              title
              date(formatString: "DD MMMM, YYYY")
              category


              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 140)  {
                      ...GatsbyImageSharpFluid_noBase64
                    }
              } }




            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `} 
render={data => (

    
    <PostWrap>
    
      <div style={{textAlign:"center"}}>
    <p >
      In holonoptic™ you will control our company, the GLAZIAL conglomerate.</p>



      </div>
    

    <div className="allPosts" >
    { data.allMarkdownRemark.edges.slice(0,2).map(({ node }, index) =>
    
    
    {                            
return (
            <div key={index} className="post" >
            <Link 
                to={node.fields.slug}
            >
                <Img fluid={node.frontmatter.thumbnail.childImageSharp.fluid} alt="" className="postImage" />
                    <div className="postTexts">
                        <h4 className="postTitle">{node.frontmatter.title}</h4>
                        <p className="postDate">{node.frontmatter.date}</p>
                        <p className="postExcerpt">{node.excerpt}</p>
                    </div>
            </Link>
            </div>
            );
    }
    )}

</div>

<div style={{marginTop: "2vw", textAlign: "center"}}>
<Link to="/holonoptic/world" >SEE ALL POSTS</Link>
</div>

  </PostWrap>


)}
/>
)


export default GlazialReduced