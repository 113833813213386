



import React from 'react'








class RestComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
  this.fetch ();
  this.interval = setInterval(() => this.fetch(), 1000);
  }



  componentWillUnmount() {
    clearInterval(this.interval);
  }


fetch ()  {     fetch("https://gesicht.pythonanywhere.com/gesicht_net_output", { method: 'get', mode: 'cors' })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.reverse()
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      ) }


  componentDidUpdate(prevprops) {
    if (prevprops.needsupdate !== this.props.needsupdate) { this.fetch()}
  }


  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <>
          {items.map((item, index) => (
            <p key={index}>


              <span className="senderDate">{item.time}</span> <br></br>
              <span className="senderName">{item.name}:</span>  <span className="senderMessage">{item.message}</span> 
            </p>
          ))}
        </>
      );
    }
  }
}






// { item.name === remember ? ( <p>this</p>  ) : (<p>another thing</p>)  }








class Post extends React.Component {



  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // name
    this.state = {name: "visitor" + Math.floor((Math.random() * 1000) + 1)}
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }



  handleSubmit(event) {
    event.preventDefault();
    

    fetch("https://gesicht.pythonanywhere.com/gesicht_net_input", {     method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }, body: JSON.stringify ({ name: this.state.name, message :this.state.value})   }).then((  ) => {

      console.log(this.props.onChange)
    this.props.onChange()
  })


    this.setState({value: ""});


  }



    focus () {
      document.getElementById('text_field').focus();
    }









      render() {
        return (
          <form onSubmit={this.handleSubmit}>


              <span id="black">
              <input type="text" maxLength="60" value={this.state.value}   onChange={this.handleChange} ref={(input) => { this.textInput = input; }} autoComplete="off" id="text_field"  onClick={this.focus} autoFocus  />  
              
              <input  id="enter" type="submit" value=" ENTER ↵" />

              

              <h6>name {this.state.name}</h6>   

              </span>

            


          </form>
        );
      }
    }





    class ChatPostFetch extends React.Component {

      constructor(props) {
        super(props);
        this.state = {needsupdate: true};
      }



     






      onChange() { 
        this.setState ({needsupdate:!this.state.needsupdate});

        console.log(this.state.needsupdate);

      }

      render() { return(

        <>
        
        <div className="scrollable" id="scrollable_id">
                    <RestComponent needsupdate={this.state.needsupdate} />


                        
                        <div id="bottom"></div>
        </div>

      <Post onChange={this.onChange.bind(this)} />

      </>
    )}
     }



export default ChatPostFetch;
