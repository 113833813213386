import React from 'react'


import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./rotating_eye.css"




function withImageData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={graphql`
          query {

            EyeShape: file(relativePath: { eq: "eye/eye.png" }) {
              ...fluidImage
            }
            IrisShape: file(relativePath: { eq: "eye/iris.png" }) {
              ...fluidImage
            }

          }
      `}
      render={data => <WrappedComponent {...props} imageData={data} />}
    />
  );
}

const EyeShape = withImageData(props => (

<>

  <div id="eye">
    <Img fluid={props.imageData.EyeShape.childImageSharp.fluid} id="eye" />
  </div>
  <div id="iris">
  <Img fluid={props.imageData.IrisShape.childImageSharp.fluid} id="iris" />
  </div>

</>

));



export default (props) =>
<>
  <div className="eye_iris" id="eye_iris">
             <EyeShape />
  </div>

</>


// export default (props) =>
// <>
//   <div className="eye_iris" id="eye_iris">
//              <img src={eye} id="eye" alt="" />
//              <img src={iris} id="iris" alt="" />
//   </div>

// </>











