import React from 'react'
import ToggleDisplay from 'react-toggle-display';


import "./gesichtSpace.css"





class ResultsGET extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      showExclusive: "none",
    };
  }

  componentDidMount() {
  this.fetch ();
  }

  handleClickExclusive(string) {
    this.setState({
      exclusiveShow: string
    });
  }


fetch ()  {     fetch("https://gesicht.pythonanywhere.com/GET_results", { method: 'get', mode: 'cors' })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      ) }


  componentDidUpdate(prevprops) {
    if (prevprops.needsupdate !== this.props.needsupdate) { this.fetch()}
  }



  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
<>
{items.map((item, index) => (

<div id="panopticResults" key={index} 
           style= {{
             animation: "expand 0.5s",
             position: "absolute",
             top: "-4vh",
           }}> 

  <div style={{
          position: "absolute",
          transform: "rotate(" + (360/items.length)*index + "deg)",
          transformOrigin: "center left",
          top: "50vh",
          left: "50vw",

          zIndex: "2",

          width: 20*item.percentage/item.relative_highest_percentage +"vw",
          height: "3px",
          boxShadow: "0px 0px 20px #77d2ff, 0px 0px 20px #77d2ff",
          background: "#77d2ff",

          opacity: "0",
          animation: "expand 3s forwards",
          animationDelay: index * 0.1 +"s"
        }}></div>

  <div style={{
          position: "absolute",
          transform: "rotate(" + (360/items.length)*index + "deg)",
          transformOrigin: "center left",
          top: "50vh",
          left: "50vw",

          zIndex: "2",

          width:20 +"vw",
          height: "3px",
          boxShadow: "0px 0px 20px #77d2ff, 0px 0px 20px #77d2ff",
          background: "#77d2ff",

          opacity: "0",
          animation: "opacity3 3s forwards",
          animationDelay: index * 0.05 +"s"             
        }}></div>



  <div style={{
              position: "absolute",
              transform: "rotate(" + (360/items.length)*index + "deg)",
              transformOrigin: "center left",
              top: "50vh",
              left: "50vw",

              zIndex: "2",

              width:20 +"vw",
              height: "30px",

              opacity: "0",
              animation: "expand 2.5s forwards",
              animationDelay: 0.2 +"s"
            }}>



  <div style={{
      float:"right",
      position: "absolute",
      right: "-1vh",
      transform: "rotate(-" + (360/items.length)*index + "deg)",

      zIndex: "2",
    }}>


  <ToggleDisplay if={this.state.exclusiveShow !== item.name}  >
        <button id="panopticButton" onClick={ () => this.handleClickExclusive( item.name ) }>{item.name}</button>
  </ToggleDisplay>

  <ToggleDisplay if={this.state.exclusiveShow === item.name}  >
      <button id="panopticButton_selected" onClick={ () => this.handleClickExclusive("none") }>{item.name}</button>
  </ToggleDisplay>



  </div>
      </div>



  <ToggleDisplay if={this.state.exclusiveShow === item.name}  >
      <div style={{width: "80vw", left: "20vw", top: "0", height: "100vh", position: "absolute"}}>

    <div style={{position: "absolute", right:"0", width: "20vw", padding: "20px"}}>

    <h3>{item.name}</h3>
    <h5>{item.description}</h5>


    <div style={{height: "30px", width: 20*100/100 +"vw", background:"#ffffff60"}}> 
          <div style={{height: "30px", width: 20*item.percentage/100 +"vw", background:"white", animation: "zoom 0.5s"}}> </div>
    </div>

    <p style={{marginTop: "0.5em", marginBottom: "2em"}}>The average test subject showed <span style={{color: "#77d2ff"}}>{Math.round(item.percentage * 100) / 100}%</span> affinity for <span style={{color: "#77d2ff"}}>{item.name}</span></p>
    


    {item.reasonings.map((reasoning, index) => 
    <>
    <div style={{height: "10px", width: 20*100/100 +"vw", background:"#77d2ff60" }}>
    <div style={{height: "10px", width: 20*reasoning.absolute/item.absolute +"vw", background:"#77d2ff", animation: "zoom 0.5s"}}> 
    </div></div>

                        
    <p key={index} style={{marginTop: "0.5em", marginBottom: "2em"}}>
    <span style={{color: "#77d2ff"}}>{reasoning.absolute} players </span>
    {reasoning.name}</p>
                        
    </>)}</div></div></ToggleDisplay></div>
))}

        </>
      );
    }
  }
}




export default ResultsGET;