import React from 'react'

import {CenterContainer} from "../basics"
import Player from "./eye_as_player"
import Menu from "./eye_as_menu.js"


import "./ceotest_title.css"
import "./glitch.css"

import "./initial_scroll.js"





// for the scrolldown
var Scroll  = require('react-scroll');
var Link       = Scroll.Link;
var Element    = Scroll.Element;




export default () =>
<>

  <div style={{ height:"100vh"}}>



    <Player />
    <Menu />

    <CenterContainer>



      <h1 className="glitch" data-text="holonoptic">holonoptic</h1>

    </CenterContainer>












  </div>
  <Link to="scroll_to_description" spy={true} smooth={true} offset={0} duration={1000}>

  <p style={{ position:"absolute", fontSize:"100px", bottom:"-30px", color:"#ffffff"}} id="more">
          ⇣
      </p>
      </Link>


      <Element name="scroll_to_description" className="element" style={{ position: "absolute", bottom: "-4vw"}}></Element>


</>
