import React from 'react'
import Link from "gatsby-link"
import Layout from '../../components/layout'
import Landing from "../../components/landing/landing"
import Features from "../../components/gesicht/features"
import GlazialReduced from "../../components/glazial/glazial_reduced"
import {CenterContainer, Wrap, SiteTitle} from "../../components/basics"

// IMAGES
import { GlacierImage, GlazialLogoWhite } from '../../components/images'
import {PanopticTestNoGradient} from "../../components/glazialcom/glazialComponents"

// video
import trailer from '../../images/Trailer.mp4'
import VideoButton from "../../components/apps/playvideo"

// screenshot
import ScreenshotsScrollable from '../../components/gallery/screenshots_scrollable'

// videos
import ReactPlayer from 'react-player'
import videobg from '../../images/background.mp4'

// alternative image for video
// <img src={prime_tower} alt="" style={{width: "100%", marginTop: "2vh", marginBottom: "2vh"}} />


// for the scrolldown
var Scroll2  = require('react-scroll');
var Link2     = Scroll2.Link;
var Element2    = Scroll2.Element;



export default () =>
<>
<SiteTitle string="holonoptic | nonlinear story game | psychological realism" />
  <Layout>
      <Landing/>
        <div style={{position: "relative", marginTop:"4vw", marginBottom: "4vw"}}>
        <div style={{width: "100%"}}>
            <ReactPlayer url={videobg} playing loop width="100%" height="auto" />

       <div style={{textAlign:"center", position:"absolute", top: "1vw", width: "100%"}}>
                                                  <Wrap >
                                                              <div class="backgroundColor80"  style={{padding:"1vw", margin: "auto"}}>
                                                                      <h2>A nonlinear story game with psychological realism set in a corporate simulation.</h2>

                                                                      <p>We are testing potential applicants for the newly vacant position of CEO.</p>
                                                                      <p>In holonoptic™, you will control our very own company, the Glazial Group, and face both psychological and economic challenges. </p>
                                                                         <p>Our goal is to assess your ideological and mental compability with our company's new software, <Link2 to="scroll_to_gesicht" spy={true} smooth={true} offset={0} duration={1000}>Geist_AI™</Link2>.
                                                                      </p>
                                                                      <VideoButton video={trailer} label="Recruiting Video" />
                                                              </div>
                                                  </Wrap>
                                  </div>
        </div>
</div>



 <Element2 name="scroll_to_gesicht" className="element" />
  <Features />
  <ScreenshotsScrollable />


<>
  <div style={{ marginTop: "10vw", 
                height: "100vh",
                width: "100vw",
                backgroundImage: "linear-gradient(180deg, transparent 0%, rgba(255,255,255,1))",
                                  }}>



        <div style={{height:"100vh", width: "100vw", zIndex:"-2", background:"white", position: "absolute"}}></div>
            <div style={{ position: "absolute",
                            height: "100vh",
                            width: "100vw",
                            zIndex: "-1",
                            overflow: "hidden",
                            background: "white",
                              }}>
                                        
                                        
                  <GlacierImage  />
            </div>


                          <Wrap>


                                          <div class="backgroundColor80"  style={{padding: "1vw", marginTop: "3vw"}}>
                                            <CenterContainer>
                                                  <Link to="/holonoptic/world" >
                                                        <div alt="" id="glazial_logo" className="glazial_logo_glow" >
                                                            <GlazialLogoWhite />
                                                        </div>
                                                  </Link>
                                            </CenterContainer>

                                            <GlazialReduced  />
                                            </div>
                            
                            </Wrap>



  </div>
</>








  <div style={{paddingTop: "10vw", paddingBottom: "10vw"}}>

  <section>
    <a id="download" href="none">
    </a>
</section>




  <Wrap>



    <div style={{paddingTop:"5vw"}}>

    <CenterContainer>
      <Link to="/holonoptic">
        <PanopticTestNoGradient />
      </Link>
    </CenterContainer>

                  <div style={{height: "5vw"}} />


    <CenterContainer>
      <h1>Download</h1>
    </CenterContainer>
    </div>



    <h1>⬇</h1>

    <p>holonoptic™ free demo (ALPHA):</p>
    <p>PC / MAC / Linux</p>
    Download the latest version on <a href="https://glazial.itch.io/holonoptic">itch.io</a><p/>

    {/* <a href="http://gesicht.space/download_demo/holonoptic-0.2.0-pc.zip">PC / LINUX (183 MB)</a><p/>
    <a href="http://gesicht.space/download_demo/holonoptic-0.2.0-mac.zip">MAC (166 MB)</a><p/> */}


    






</Wrap>
</div>

  </Layout>
</>
